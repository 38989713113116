const config = {
  apiGateway: {
    REGION: 'us-east-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://kpesim1bhc.execute-api.us-east-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://a1fcje25he.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://dd5jn2c6z2.execute-api.us-east-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.rossing.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://407rcjdf6d.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;